// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../src/style.scss';
import '../src/css/icofont.min.css';
import '../src/css/LineIcons.css';
import '../src/css/viewer.min.css';
import '../stylesheets/application';
import 'select2';
import 'select2/dist/css/select2.css';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';
// import "froala_editor.min";
// import "froala_style.min";

window.Swal = Swal;

global.toastr = require('toastr');

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('jquery');
require('popper.js').default;
require('bootstrap');
require('feather-icons');
require('packs/viewer.min');
require('apexcharts');
require('packs/apex-charts/month-sales-statistics');
require('packs/apex-charts/apex-line-charts');
require('packs/apex-charts/apex-area-charts');
require('packs/apex-charts/apex-bar-charts');
require('packs/apex-charts/apex-mixed-charts');
require('packs/apex-charts/apex-pie-donuts-charts');
require('packs/apex-charts/sales-by-countries');
require('packs/apex-charts/order-summary');
require('packs/apex-charts/visitors-overview');
require('packs/apex-charts/leads-stats');
require('packs/apex-charts/apex-column-charts');
require('packs/apex-charts/custom-chart');
require('packs/custom');
require('inputmask');
require('select2');
require('sweetalert2');
require('packs/patient_guides');
require("trix");
require("@rails/actiontext");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).on('turbolinks:load', function () {
    $('#name_search').change(function () {
        var obj = {
            id: this.value,
        };
        $.post('/util/get_info_person', obj, function (data) {
        });
    });
    $(document).ready(function () {
        $('#person_id').select2();
        $('#patient_guide_person_id').select2();
        $('#patient_guide_doctor').select2();
        $('#doctor').select2();
        $('#procedure_item').select2();
        $('#patient_guide_procedure_item').select2();
        $('#favorite_item').select2();
        $('#patient_guide_favorite_item').select2();
        $('#bank_id').select2();
        $('#procedure').select2();
        $('#health_insurance_id').select2();
        //$('#health_plan_id').select2();

        // filtros 
        $('#q_person_person_health_plan_health_insurance_id_eq').select2();
        $('#q_person_person_health_plan_id_eq').select2();
        $('#q_person_id_eq').select2();
        $('#q_patient_guide_procedures_procedure_category_procedure_id_eq').select2();
        $('#q_history_status_patient_guides_status_patient_guide_id_eq').select2();
        $('#q_doctor_eq').select2();


    });

    var imReimbursement = new Inputmask('numeric', {
        numericInput: true,
        radixPoint: ',',
        groupSeparator: '.'
    });
    var selectorReimbursement = $('#value_total');
    imReimbursement.mask(selectorReimbursement);

    var selectorReimbursement2 = $('#reimburse');
    imReimbursement.mask(selectorReimbursement2);

    // buscar dados paciente
    $('#person_id').on('select2:select', function (e) {
        var e = document.getElementById('person_id');
        console.log('E', e);
        var person_id = e.options[e.selectedIndex].value;
        console.log('PERSON_ID', person_id);
        let dados = {person_id: person_id};
        console.log('DADOS', dados);
        $.ajax({
            data: dados,
            type: 'GET',
            url: '/patient-guides/find_patient',
            cache: false,
            success: function (response) {
                $('#document').val(response['patient']['document']);
                $('#cellphone').val(response['patient']['cellphone']);
                $('#birthdate').val(response['patient']['birthdate']);
                $('#gender').val(response['patient']['gender']);
                $('#email').val(response['patient']['email']);

                $('#number').val(response['patient_bank_data']['number']);
                $('#name_bank').val(response['patient_bank_data']['name_bank']);
                $('#agency').val(response['patient_bank_data']['agency']);
                $('#account').val(response['patient_bank_data']['account']);

                $('#document_holder').val(
                    response['patient_holder']['document_holder']
                );
                $('#name_holder').val(response['patient_holder']['name_holder']);
                $('#birthdate_holder').val(
                    response['patient_holder']['birthdate_holder']
                );
                $('#code_health_plan').val(
                    response['patient_holder']['code_health_plan']
                );
                $('#date_validate').val(response['patient_holder']['date_validate']);
                $('#mother_holder').val(response['patient_holder']['mother_holder']);
                $('#observation').val(response['patient_holder']['observation']);
                $('#reimburse').val(parseFloat(response['patient_holder']['reimbursement']));


                $('#insurance').val(response['patient_insurance']['name']);

                $('#health_plan').val(response['patient_plan']['name']);
                $('#health_plan_id').val(response['patient_plan']['id']);
            },
        });
    });

    $('#table_procedure').on('click', '#remove_item', function () {
        var row = this.parentNode.parentNode;
        row.parentNode.removeChild(row);
    });

    var im = new Inputmask('999.999.999-99');
    var selector = $('#document');
    im.mask(selector);

    var imPhone = new Inputmask('(99) 99999-9999');
    var selectorPhone = $('#cellphone');
    imPhone.mask(selectorPhone);

    var imZipCode = new Inputmask('99999-999');
    var selectorZipCode = $('#zipcode');
    imZipCode.mask(selectorZipCode);

    var selectorDocumentHolder = $('#document_holder');
    im.mask(selectorDocumentHolder);

    var imReimbursement = new Inputmask('numeric', {
        numericInput: true,
        radixPoint: ',',
        groupSeparator: '.',
    });
    var selectorReimbursement = $(
        '#reimbursement'
    );
    imReimbursement.mask(selectorReimbursement);

    function limpa_formulário_cep() {
        // Limpa valores do formulário de cep.
        $('#street').val('');
        $('#neighborhood').val('');
        $('#state').val('');
        $('#city').val('');
    }

    //Quando o campo cep perde o foco.
    $('#zipcode').blur(function () {
        //Nova variável "cep" somente com dígitos.
        var cep = $(this).val().replace(/\D/g, '');

        //Verifica se campo cep possui valor informado.
        if (cep != '') {
            //Expressão regular para validar o CEP.
            var validacep = /^[0-9]{8}$/;

            //Valida o formato do CEP.
            if (validacep.test(cep)) {
                //Preenche os campos com "..." enquanto consulta webservice.
                $('#street').val('...');
                $('#neighborhood').val('...');
                $('#state').val('...');
                $('#city').val('...');

                //Consulta o webservice viacep.com.br/
                $.getJSON(
                    'https://viacep.com.br/ws/' + cep + '/json/?callback=?',
                    function (dados) {
                        if (!('erro' in dados)) {
                            //Atualiza os campos com os valores da consulta.
                            $('#street').val(dados.logradouro);
                            $('#neighborhood').val(dados.bairro);
                            if (dados.localidade === 'Embu das Artes'){
                                $('#city').val("Embu-Guaçu");
                            }else{
                                $('#city').val(dados.localidade);
                            } 
                            
                            $('#state').val(dados.uf);
                        } //end if.
                        else {
                            //CEP pesquisado não foi encontrado.
                            limpa_formulário_cep();
                            alert('CEP não encontrado.');
                        }
                    }
                );
            } //end if.
            else {
                //cep é inválido.
                limpa_formulário_cep();
                alert('Formato de CEP inválido.');
            }
        } //end if.
        else {
            //cep sem valor, limpa formulário.
            limpa_formulário_cep();
        }
    });

    // atualiza cidades ao escolher novo estado
    $('#state_id').on('change', function () {
        loadCitiesFromState();
    });

    // marca cidade selecionada
    $('#city_id').on('change', function () {
        var selected = $('#city_id option:selected').val();
        $('#city_selected').val(selected);
    });

    // atualiza cidades ao escolher novo estado
    $('#health_insurance_id').on(
        'change',
        function () {
            loadHealthPlansFromHealthInsurance();
        }
    );

    loadCitiesFromState();

    var preview = $('.upload-preview .img-person');
    var previewDocument = $('.upload-preview .img-document');
    var previewStamp = $('.upload-preview .img-stamp');
    var previewLicensed = $('.upload-preview .img-licensed');

    $('#licensed_logo').change(function (event) {
        var input = $(event.currentTarget);
        var file = input[0].files[0];
        var reader = new FileReader();
        var image_base64 = '';
        reader.onload = function (e) {
            image_base64 = e.target.result;
            previewLicensed.attr('src', image_base64);
        };
        reader.readAsDataURL(file);
    });

    $('#person_avatar').change(function (event) {
        var input = $(event.currentTarget);
        var file = input[0].files[0];
        var reader = new FileReader();
        var image_base64 = '';
        reader.onload = function (e) {
            image_base64 = e.target.result;
            preview.attr('src', image_base64);
        };
        reader.readAsDataURL(file);
    });

    $('#person_photoDocument').change(function (event) {
        var input = $(event.currentTarget);
        var file = input[0].files[0];
        var reader = new FileReader();
        var image_base64 = '';
        reader.onload = function (e) {
            image_base64 = e.target.result;
            previewDocument.attr('src', image_base64);
        };
        reader.readAsDataURL(file);
    });

    $('#person_stampDoctor').change(function (event) {
        var input = $(event.currentTarget);
        var file = input[0].files[0];
        var reader = new FileReader();
        var image_base64 = '';
        reader.onload = function (e) {
            image_base64 = e.target.result;
            previewStamp.attr('src', image_base64);
        };
        reader.readAsDataURL(file);
    });

    $('#add_procedure').click(function (event) {
        event.preventDefault();

        var e = document.getElementById('procedure');
        var ordem = $('#order');

        $(
            '#table_procedure'
        ).append(`<tr><input type="hidden" id="procedures[]" name="procedures[]" value="${e.options[e.selectedIndex].value}">
                      <input type="hidden" id="ordens[]" name="ordens[]" value="${ordem.val()}">
                            <td>${e.options[e.selectedIndex].value}</td>
                            <td>${e.options[e.selectedIndex].text}</td>
                            <td>${ordem.val()}</td>
                            <td class="text-center"><a id="remove_item" href="javascript:" class="btn btn-link text-danger p-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x icon wh-15"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                        </a></td></tr>`);
    });
});

function loadCitiesFromState() {
    var state_id = $('#state_id option:selected').val();
    if (state_id) {
        var obj = {
            state_id: state_id,
        };

        $.post('/util/get_formated_cities_from_state', obj, function (data) {
            $('#city_id').empty();
            $.each(data, function (key, value) {
                $('#city_id').append(
                    $('<option></option>').attr('value', value.id).text(value.name)
                );
            });
        });
    }
}

function loadHealthPlansFromHealthInsurance() {
    var health_insurance_id = $(
        '#health_insurance_id option:selected'
    ).val();
    if (health_insurance_id) {
        var obj = {
            health_insurance_id: health_insurance_id,
        };

        $.post('/util/get_health_plans_from_health_insurance', obj, function (
            data
        ) {
            $('#health_plan_id').empty();
            $.each(data, function (key, value) {
                $('#health_plan_id').append(
                    $('<option></option>').attr('value', value.id).text(value.name)
                );
            });
        });
    }
}


// patient_guide.js
$(document).on('turbolinks:load', function () {
    $('#bank_id').select2();
    $('#procedure').select2();
    // $('#health_insurance_id').select2();
    // $('#health_plan_id').select2();
    var imReimbursement = new Inputmask('numeric', {
        numericInput: true,
        radixPoint: ',',
        groupSeparator: '.'
    });
    var selectorReimbursement = $('#value_total');
    imReimbursement.mask(selectorReimbursement);

    var selectorReimbursement2 = $('#reimburse');
    imReimbursement.mask(selectorReimbursement2);


    var selectorReimbursement4 = $('#value');
    imReimbursement.mask(selectorReimbursement4);

    var selectorReimbursement5 = $('.procedure_value');
    imReimbursement.mask(selectorReimbursement5);

    $(document).on('input', '.procedure_value', function () {
        sumValueTotal();
    });
    $(document).on('input', '.procedure_value_index', function () {
        sumValueindex();
    });

    function sumValueTotal() {
        var total = 0.0;
        $('#table_procedure_item tr').each(function () {
            var value = $(this).find('td:eq(3)').find('input').val() || 0
            var reimburse = value.toString().replace('.','').replace(',','.');
            //alert(reimburse) 
            total += parseFloat(reimburse)
        });
        //alert(total)
        $('#value_total').val(total.toString().replace('.', ','));
    }

    function sumValueindex() {
        var total = 0.0;
        $('#table_procedure_item tr').each(function () {

            if ($(this).find('td:eq(4)').find('input').val() !== undefined) {
                var value = $(this).find('td:eq(4)').find('input').val() || 0
                var value_plan = parseFloat(value.toString().replace(/'/g,'').replace(/,/g, '.'));
                var value2 = $('#value').val() || 0
                var index = parseFloat(value2.toString().replace(/,/g, '.'));
                //console.log(value_plan)
                //console.log(value.toString().replace(/'/g,''))
                //console.log(index)


                if(isNaN(value_plan)){
                    total = $(this).find('td:eq(3)').find('input').val()
                }else{
                    total = value_plan * index
                }
                
                $(this).find('td:eq(3)').find('input').val(total.toString().replace('.', ','))
            }

        });
        sumValueTotal();
    }

    $('#copy_data').change(function () {
        console.log(this.checked);
        if (this.checked) {
            $('#name_holder').val($('#name').val());
            $('#document_holder').val($('#document').val());
            $('#birthdate_holder').val($('#birthdate').val());
            $('#mother_holder').val($('#mother').val());
            $('#father_holder').val($('#father').val());
        } else {
            $('#name_holder').val('');
            $('#document_holder').val('');
            $('#birthdate_holder').val('');
            $('#mother_holder').val('');
            $('#father_holder').val('');
        }
    });

    // Adicionar Procedimentos ou Exames na Tabela
    $('#add_procedure_item').click(function (event) {
        event.preventDefault();
        var e = document.getElementById('procedure_item');
        var procedure_id = e.options[e.selectedIndex].value;
        let data = {
            procedure_id: procedure_id,
            patient_guide_id: $('#patient_guide_id').val(),
            value_indicie: $('#value').val()
        };
        $.ajax({
            data: data,
            type: 'GET',
            url: '/patient-guides/find_procedure',
            cache: false,
            success: function (response) {
                if (response['editable'] == false || response['editable'] == null) {
                    $(
                        '#table_procedure_item'
                    ).append(`<tr><td><input type="hidden" id="procedures[]" name="procedures[]" value="${response['id']}"></td>
                                    <td class="pt-4">${response['name']}</td>
                                    <td class="pt-4">${response['type']}</td>
                                    <td><input id="procedure_value" class="form-control procedure_value" disabled name="procedure_value" value='${response['value_procedure']}'></td>
                                    <td><input type="hidden" name="procedure_value_multiplo" id="procedure_value_multiplo" value="'${response['procedure_value_multiplo']}'" class="form-control procedure_value" style="text-align: right;"></td>
                                    <td class="text-center pt-4"><a id="remove_item" href="javascript:" class="btn btn-link text-danger p-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x icon wh-15"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                </a></td></tr>`);

                } else {
                    $(
                        '#table_procedure_item'
                    ).append(`<tr><td><input type="hidden" id="procedures[]" name="procedures[]" value="${response['id']}"></td>
                                    <td class="pt-4">${response['name']}</td>
                                    <td class="pt-4">${response['type']}</td>
                                    <td><input id="procedure_value" class="form-control procedure_value" name="procedure_value" value='${response['value_procedure']}'></td>
                                    <td><input type="hidden" name="procedure_value_multiplo" id="procedure_value_multiplo" value="'${response['procedure_value_multiplo']}'" class="form-control procedure_value" style="text-align: right;"></td>
                                    <td class="text-center pt-4"><a id="remove_item" href="javascript:" class="btn btn-link text-danger p-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x icon wh-15"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                </a></td></tr>`);
                }


                var imReimbursement = new Inputmask('numeric', {
                    numericInput: true,
                    radixPoint: ',',
                    groupSeparator: '.'
                });
                var selectorReimbursement3 = $('.procedure_value');
                imReimbursement.mask(selectorReimbursement3);
                sumValueTotal();

            },
            error: function (ajaxContext) {
                alert('Não Foi possivel salvar a guia');
            },
        });

    });

    // Remove item Procedimentos ou Exames da Tabela
    $('#table_procedure_item').on('click', '#remove_item', function () {
        var row = this.parentNode.parentNode;
        row.parentNode.removeChild(row);
        sumValueTotal();
    });

    $('#table_exam_payments_patient_guide').on('click', '#edit_item_payment', function () {
        var row = this.parentNode.parentNode;
        var patient_guide_id = row.getElementsByTagName("td")[0].innerText;
        var value_total = row.getElementsByTagName("td")[2].innerText;
        var value_paid = row.getElementsByTagName("td")[3].innerText;
        var date_payment = row.getElementsByTagName("td")[4].innerText;
        var type_payment = row.getElementsByTagName("td")[5].innerText;
        $("#financ_patient_guide_id").val(patient_guide_id);
        $("#financ_value_total").val(value_total);
        $("#financ_value_paid").val(value_paid);
        $("#financ_date_payment").val(date_payment);
        $("#financ_type_payment").val(type_payment);
    });

    // Adicionar Favoritos de Procedimentos ou Exames na Tabela
    $('#add_favorite_item').click(function (event) {
        event.preventDefault();

        var e = document.getElementById('favorite_item');
        var favorite_id = e.options[e.selectedIndex].value;
        let data = {
            favorite_id: favorite_id,
            value_indicie: $('#value').val(),
            patient_guide_id: $('#patient_guide_id').val()
        };
        $.ajax({
            data: data,
            type: 'GET',
            url: '/patient-guides/find_procedures_favorite',
            cache: false,
            success: function (response) {
                $.each(response['favorites'], function (k, v) {
                    if (v['editable'] == false || v['editable'] == null) {
                        $(
                            '#table_procedure_item'
                        ).append(`<tr><td><input type="hidden" id="procedures[]" name="procedures[]" value="${v['id']}">${v['id']}</td>
                                <td>${v['name']}</td>
                                <td>${v['type']}</td>
                                <td><input id="procedure_value" class="form-control procedure_value" disabled name="procedure_value" value='${v['value_procedure']}'></td>
                                <td><input type="hidden" name="procedure_value_multiplo" id="procedure_value_multiplo" value="'${v['procedure_value_multiplo']}'" class="form-control procedure_value" style="text-align: right;"></td>
                                <td class="text-center"><a id="remove_item" href="javascript:" class="btn btn-link text-danger p-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x icon wh-15"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                            </a></td></tr>`);

                    } else {
                        $(
                            '#table_procedure_item'
                        ).append(`<tr><td><input type="hidden" id="procedures[]" name="procedures[]" value="${v['id']}">${v['id']}</td>
                                    <td>${v['name']}</td>
                                    <td>${v['type']}</td>
                                    <td><input id="procedure_value" class="form-control procedure_value" name="procedure_value" value='${v['value_procedure']}'></td>
                                    <td><input type="hidden" name="procedure_value_multiplo" id="procedure_value_multiplo" value="'${v['procedure_value_multiplo']}'" class="form-control procedure_value" style="text-align: right;"></td>
                                    <td class="text-center"><a id="remove_item" href="javascript:" class="btn btn-link text-danger p-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x icon wh-15"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                </a></td></tr>`);
                    }
                    var imReimbursement = new Inputmask('numeric', {
                        numericInput: true,
                        radixPoint: ',',
                        groupSeparator: '.'
                    });
                    var selectorReimbursement3 = $('.procedure_value');
                    imReimbursement.mask(selectorReimbursement3);
                    sumValueTotal();
                });

            },
            error: function (ajaxContext) {
                alert('Não Foi possivel salvar a guia');
            },
        });
    });

    // salvar guia do paciente
    $('#save_patient').click(function (event) {
        event.preventDefault();

        Swal.fire({
            title: 'Deseja salvar os dados do paciente?',
            text: 'Salvando os dados do paciente é gerada uma guia!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, desejo salvar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                var e = document.getElementById('person_id');
                var person_id = e.options[e.selectedIndex].value;
                var e1 = document.getElementById('doctor');
                var doctor = e1.options[e1.selectedIndex].value;
                var value = $('#reimburse').val();
                var reimburse = value.replace(/[^0-9,-]+/g, '').replace(/,/g, '.');
                reimburse = parseFloat(reimburse);
                let dados = {
                    person_id: person_id,
                    doctor: doctor,
                    date_patient_guide: $('#date_patient_guide').val(),
                    health_plan_id: $('#health_plan_id').val(),
                    reimburse: reimburse,
                    observation: $('#observation').val(),
                    patient_guide_id: $('#patient_guide_id').val(),
                };
                $.ajax({
                    data: dados,
                    type: 'POST',
                    url: '/patient-guides/save_patient_guide',
                    cache: false,
                    success: function (response) {
                        $('#patient_guide_id').val(response['patient_guide_id']);
                        $('#id').val(response['patient_guide_id']);
                        if (window.location.pathname.toString().includes("new")) {
                            $('#value').val(response['valor'].toString().replace('.', ','));
                        }
                        $('a.target').attr('href', window.location.origin.toString() + '/patient-guides/download.pdf?patient_guide_id=' + response['patient_guide_id'].toString());
                        $('a.target-report').attr('href', window.location.origin.toString() + '/patient-guides/download_document_bra.pdf?patient_guide_id=' + response['patient_guide_id'].toString());
                        $('#table_history_status_patient_guide').append(`<tr><td>${response[
                            'created_at'
                            ]
                            .replace(/T/, ' ')
                            .replace(/\..+/, '')}</td>
                            <td>${response['person_id']}</td>
                            <td>${response['next_action_date']}</td>
                            <td>${response['status_patient_guide_id']}</td>
                            <td>${response['description']}</td>
                            </tr>`);
                        Swal.fire(
                            'Operação realizada com sucesso!',
                            'Guia do paciente gerada com sucesso.',
                            'success'
                        );
                        toastr.success(
                            'Guia salva com sucesso!',
                            'Operação realizada com Sucesso'
                        );
                    },
                    error: function (ajaxContext) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Não foi possivel realizar a operação',
                        });
                    },
                });
            }
        });
    });

    // salvar exames e procedimentos da guia do paciente
    $('#save_patient_guide_exams_procedures').click(function (event) {
        event.preventDefault();

        Swal.fire({
            title: 'Deseja salvar os procedimentos/exames na guia do paciente?',
            text: 'Salvando os procedimentos/exames poderá ser consultado na guia do paciente!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, desejo salvar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                var procedures = [];
                $('#table_procedure_item tr').each(function () {
                    var value = $(this).find('td:eq(3)').find('input').val() || 0
                    var reimburse = value.toString().replace(/,/g, '.');
                    if ($(this).find('td:eq(0)').html()) {
                        procedures.push({
                            id: $(this).find('td:eq(0)').find('input').val(),
                            value: parseFloat(reimburse)
                        });
                    }
                });
                var value = $('#value_total').val();
                var value_total = value.replace(/[^0-9,-]+/g, '').replace(/,/g, '.');
                value_total = parseFloat(value_total);
                let dados = {
                    patient_guide_id: $('#patient_guide_id').val(),
                    procedures: JSON.stringify(procedures),
                    value_total: value_total,
                    value_index_health_insurance: parseFloat($('#value').val().toString().replace(/,/g, '.')),
                    patient_guide_nr_nota_fiscal: $("#patient_guide_nr_nota_fiscal").val(),
                    patient_guide_date_of_nf: $("#patient_guide_date_of_nf").val(),
                    patient_guide_cnpj_report: $("#patient_guide_cnpj_report").val(),
                };
                $.ajax({
                    data: dados,
                    type: 'POST',
                    url: '/patient-guides/save_patient_guide_procedures_exams',
                    cache: false,
                    success: function (response) {
                        Swal.fire(
                            'Operação realizada com sucesso!',
                            'Procedimentos/exames salvos com sucesso.',
                            'success'
                        );
                        toastr.success(
                            'Produtos/Exames salvo com sucesso!',
                            'Operação realizada com Sucesso'
                        );
                        $("#favorite_item").select2("val", "");
                        $("#procedure_item").select2("val", "");
                        if (window.location.pathname.toString().includes("edit")) {
                            //location.reload();
                        }
                    },
                    error: function (ajaxContext) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Não foi possivel realizar a operação',
                        });
                    },
                });
            }
        });
    });

    // salvar exames e relatorios da guia do paciente
    $('#save_patient_guide_exams_reports').click(function (event) {
        event.preventDefault();

        Swal.fire({
            title: 'Deseja salvar os dados do relatório na guia do paciente?',
            text: 'Salvando os dados do relatório poderá ser consultado na guia do paciente!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, desejo salvar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {

                var input = $('#image_report2');
                var file = input[0].files[0];
                var reader = new FileReader();
                var image_base64 = '';
                reader.onload = function (e) {
                    image_base64 = e.target.result;
                    let dados = {
                        patient_guide_id: $('#patient_guide_id').val(),
                        jutificative: CKEDITOR.instances.dinamic_justificative.getData() ,
                        jutificative2: CKEDITOR.instances.dinamic_justificative2.getData() ,
                        patient_guide_cnpj_report: $("#patient_guide_cnpj_report2").val(),
                        image_report: image_base64,
                    };
                    $.ajax({
                        data: dados,
                        type: 'POST',
                        url: '/patient-guides/save_patient_guide_reports',
                        cache: false,
                        success: function (response) {
                            Swal.fire(
                                'Operação realizada com sucesso!',
                                'Documento salvo com sucesso.',
                                'success'
                            );
                            toastr.success(
                                'Documento salvo com sucesso!',
                                'Operação realizada com Sucesso'
                            );
                            //$('#image').val("");
                            $('#description_document').val();
                        },
                        error: function (ajaxContext) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Não foi possivel realizar a operação',
                            });
                        },
                    });
                };
                try {
                    reader.readAsDataURL(file);
                  } catch (e) {
                    let dados = {
                        patient_guide_id: $('#patient_guide_id').val(),
                        jutificative: CKEDITOR.instances.dinamic_justificative.getData() ,
                        jutificative2: CKEDITOR.instances.dinamic_justificative2.getData() ,
                        patient_guide_cnpj_report: $("#patient_guide_cnpj_report2").val(),
                        image_report: null,
                    };
                    $.ajax({
                        data: dados,
                        type: 'POST',
                        url: '/patient-guides/save_patient_guide_reports',
                        cache: false,
                        success: function (response) {
                            Swal.fire(
                                'Operação realizada com sucesso!',
                                'Documento salvo com sucesso.',
                                'success'
                            );
                            toastr.success(
                                'Documento salvo com sucesso!',
                                'Operação realizada com Sucesso'
                            );
                            //$('#image').val("");
                            $('#description_document').val();
                        },
                        error: function (ajaxContext) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Não foi possivel realizar a operação',
                            });
                        },
                    });
                  }
 

            }
            ;
        });
    });


    // salvar status da guia do paciente
    $('#save_history_status_patient_guide').click(function (event) {
        event.preventDefault();

        Swal.fire({
            title: 'Deseja salvar os status da guia do paciente?',
            text: 'Salvando os status da guia você poderá consultar o histórico da guia do paciente!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, desejo salvar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            var e = document.getElementById('status_patient_guides_id');
            var status_patient_guides_id = e.options[e.selectedIndex].value;
            let dados = {
                status_patient_guides_id: status_patient_guides_id
            };
            $.ajax({
                data: dados,
                type: 'Get',
                url: '/patient-guides/consultar_tipo_status',
                cache: false,
                success: function (response) {
                    const required_action = response['required_action'];
                    var validado = true
                    if (required_action == true && $('#next_action_date').val() == "") {
                        var validado = false
                    }

                    if (result.isConfirmed && status_patient_guides_id != "" && validado) {

                        var e = document.getElementById('status_patient_guides_id');
                        var status_patient_guides_id = e.options[e.selectedIndex].value;
                        let dados = {
                            patient_guide_id: $('#patient_guide_id').val(),
                            status_patient_guides_id: status_patient_guides_id,
                            next_action_date: $('#next_action_date').val(),
                            description: $('#description').val(),
                        };
                        $.ajax({
                            data: dados,
                            type: 'POST',
                            url: '/patient-guides/save_history_status_patient_guide',
                            cache: false,
                            success: function (response) {
                                $('#table_history_status_patient_guide').append(`<tr><td>${response[
                                    'created_at'
                                    ]
                                    .replace(/T/, ' ')
                                    .replace(/\..+/, '')}</td>
                                    <td>${response['person_id']}</td>
                                    <td>${response['next_action_date']}</td>
                                    <td>${response['status_patient_guide_id']}</td>
                                    <td>${response['description']}</td>
                                    </tr>`);
                                Swal.fire(
                                    'Operação realizada com sucesso!',
                                    'Status da Guia salvo com sucesso.',
                                    'success'
                                );
                                toastr.success(
                                    'Status da Guia salvo com sucesso!',
                                    'Operação realizada com Sucesso'
                                );
                                $('#next_action_date').val('');
                                $('#description').val('');
                            },
                            error: function (ajaxContext) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Não foi possivel realizar a operação',
                                });
                            },
                        });


                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Favor verificar os dados inseridos',
                        });
                    }
                },
                error: function (ajaxContext) {
                },
            });
        });


    });

    $('#image').change(function () {
        var file = $('#image')[0].files[0].name;
        document.getElementById('custom-file-label').innerHTML = file;
    });
    //$('#image_report').change(function () {
    //    var file = $('#image_report')[0].files[0].name;
    //    document.getElementById('custom-file-label-report').innerHTML = file;
    //});

    // salvar documentos guia do paciente
    $('#add_documents').click(function (event) {
        event.preventDefault();

        Swal.fire({
            title: 'Deseja salvar o documento na guia do paciente?',
            text: 'Salvando o documento poderá ser vizualizado/baixado na guia do paciente!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, desejo salvar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {

                var input = $('#image');
                var file = input[0].files[0];
                var reader = new FileReader();
                var image_base64 = '';
                reader.onload = function (e) {
                    image_base64 = e.target.result;

                    var e = document.getElementById('document_type_id');
                    var document_type_id = e.options[e.selectedIndex].value;
                    let dados = {
                        patient_guide_id: $('#patient_guide_id').val(),
                        document_type_id: document_type_id,
                        image: image_base64,
                        description: $('#description_document').val(),
                    };
                    $.ajax({
                        data: dados,
                        type: 'POST',
                        url: '/patient-guides/save_patient_guide_document',
                        cache: false,
                        success: function (response) {
                            $('#table_patient_guide_document').append(`<tr>
                            <td> <img style="width: 60px;" src="${response['image']}" ></td>
                            <td>${response['created_at']
                                .replace(/T/, ' ')
                                .replace(/\..+/, '')}</td>
                                <td>${response['document_type']}</td>
                                <td>${response['description']}</td>
                                <td class="text-center">
                                <a href="javascript:" class="btn btn-link text-danger p-0 remove_document_item">
                                <input type="hidden" name="document_id" id="document_id" value="${response['id']}" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x icon wh-15"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                </a>
                                <a href="${
                                response['image']
                            }" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download icon"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                                </a>
                                </td>
                                </tr>
                                </tr>`);
                            Swal.fire(
                                'Operação realizada com sucesso!',
                                'Documento salvo com sucesso.',
                                'success'
                            );
                            toastr.success(
                                'Documento salvo com sucesso!',
                                'Operação realizada com Sucesso'
                            );
                            //$('#image').val("");
                            $('#description_document').val();
                        },
                        error: function (ajaxContext) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Não foi possivel realizar a operação',
                            });
                        },
                    });
                };
                reader.readAsDataURL(file);

            }
            ;
        });

    });
    

    $('#imprimir').click(function (event) {
        $('#patient_guide_search').attr('action', '/relatorio/download_geral');
    });
    $('#filtrar').click(function (event) {
        $('#patient_guide_search').attr('action', '/relatorio/index');
    });
    $('#excel').click(function (event) {
        $('#patient_guide_search').attr('action', '/relatorio/download_geral_excel');
    });
    // salvar financeiro da guia do paciente
    $('#save_patient_guide_payment_exmans').click(function (event) {

    });

    $('#imprimir_baixa').click(function (event) {
        $('#patient_guide_search').attr('action', '/baixa/download_geral');
    });
    $('#filtrar_baixa').click(function (event) {
        $('#patient_guide_search').attr('action', '/baixa/index');
    });
    $('#excel_baixa').click(function (event) {
        $('#patient_guide_search').attr('action', '/baixa/download_geral_excel');
    });
    $('#baixar_guias').click(function (event) {
        event.preventDefault();
        Swal.fire({
            title: 'Deseja realmente baixar essas Guias?',
            text: 'Será baixado as guias Filtradas!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, desejo Baixar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                $('#patient_guide_search').attr('action', '/baixa/baixar_guias');
                $('form#patient_guide_search').submit();
            }
        });
        
    });
    $('#revogar_baixar_guias').click(function (event) {

        event.preventDefault();
        Swal.fire({
            title: 'Deseja realmente revogar a baixa dessas Guias?',
            text: 'Será revogado a baixa das guias Filtradas!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, desejo Revogar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                $('#patient_guide_search').attr('action', '/baixa/revogar_baixar_guias');
                $('form#patient_guide_search').submit();
            }
        });
   
    });
    // salvar financeiro da guia do paciente
    $('#save_patient_guide_payment_exmans').click(function (event) {

        event.preventDefault();

        Swal.fire({
            title: 'Deseja salvar o financiero da guia do paciente?',
            text: 'Salvando o financeiro poderá ser consultado na guia do paciente!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, desejo salvar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                var value_paid = $("#financ_value_paid").val().replace(/[^0-9,-]+/g, '').replace(/,/g, '.');
                value_paid = parseFloat(value_paid);
                let dados = {
                    patient_guide_id: $('#financ_patient_guide_id').val(),
                    value_paid: value_paid,
                    date_payment: $('#financ_date_payment').val(),
                    type_payment: $("#financ_type_payment").val(),
                };
                $.ajax({
                    data: dados,
                    type: 'POST',
                    url: '/patient-guides/save_patient_guide_payment_exmans',
                    cache: false,
                    success: function (response) {
                        Swal.fire(
                            'Operação realizada com sucesso!',
                            'Financeiro salvo com sucesso.',
                            'success'
                        );
                        toastr.success(
                            'Financeiro salvo com sucesso!',
                            'Operação realizada com Sucesso'
                        );
                        $("#financ_value_total").val("");
                        $("#financ_value_paid").val("");
                        $("#financ_date_payment").val("");
                        $("#financ_type_payment").val("");
                        location.reload();
                    },
                    error: function (ajaxContext) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Não foi possivel realizar a operação',
                        });
                    },
                });
            }
        });
    });

    // salvar financeiro da guia do paciente
    $('#add_licenciado_item').click(function (event) {

        event.preventDefault();
           if(window.location.href.indexOf("new") != -1){
            $('#table_licenciados').append(`<tr>
                <td>${$('#liceciados_item').val()}</td>
                <td>${$('#liceciados_item :selected').text() }</td>
                <td><a href="javascript:" class="btn btn-link text-danger p-0 remove_licenciado_item_new">
                <input type="hidden" name="licenced_id_${$('#liceciados_item').val()}" id="${$('#liceciados_item').val()}" class="json_licensends" value="${$('#liceciados_item').val()}" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x icon wh-15"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </a></td>
                </tr>`);

           }else{
                $('#table_licenciados').append(`<tr>
                <td>${$('#liceciados_item').val()}</td>
                <td>${$('#liceciados_item :selected').text() }</td>
                <td><a href="javascript:" class="btn btn-link text-danger p-0 remove_licenciado_item">
                <input type="hidden" name="licenced_id_${$('#liceciados_item').val()}" id="${$('#liceciados_item').val()}" class="json_licensends" value="${$('#liceciados_item').val()}" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x icon wh-15"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </a></td>
                </tr>`);
           }


    });
        // remove documentos guia do paciente
    $(document).on('click', '.remove_licenciado_item', function (event) {
        event.preventDefault();
        var row = this.parentNode.parentNode;
        Swal.fire({
            title: 'Deseja remover posto atendimento',
            text: 'Será excluído o posto de atendimento permanemtemente do usuário!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, desejo excluir!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                let dados = {
                    permission_id: this.firstElementChild.value,
                };
                $.ajax({
                    data: dados,
                    type: 'POST',
                    url: '/person/employee/delete_person_permission_item',
                    cache: false,
                    success: function (response) {
                        row.parentNode.removeChild(row);
                        Swal.fire(
                            'Operação realizada com sucesso!',
                            'Documento excluído com sucesso.',
                            'success'
                        );
                        toastr.success(
                            'excluído com sucesso!',
                            'Operação realizada com Sucesso'
                        );
                    },
                    error: function (ajaxContext) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Não foi possivel realizar a operação',
                        });
                    },
                });
            }
        });
    });

    $(document).on('click', '.remove_licenciado_item_new', function (event) {
        event.preventDefault();
        var row = this.parentNode.parentNode;
        Swal.fire({
            title: 'Deseja remover posto atendimento',
            text: 'Será excluído o posto de atendimento permanemtemente do usuário!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, desejo excluir!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                        row.parentNode.removeChild(row);
                        Swal.fire(
                            'Operação realizada com sucesso!',
                            'Documento excluído com sucesso.',
                            'success'
                        );
                        toastr.success(
                            'excluído com sucesso!',
                            'Operação realizada com Sucesso'
                        );

            }
        });
    });

    // remove documentos guia do paciente
    $(document).on('click', '.remove_document_item', function (event) {
        event.preventDefault();
        var row = this.parentNode.parentNode;
        Swal.fire({
            title: 'Deseja remover esse documento na guia do paciente?',
            text: 'Será excluído o documento permanentemente na guia do paciente !',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, desejo excluir!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                let dados = {
                    patient_guide_document_id: this.firstElementChild.value,
                };
                $.ajax({
                    data: dados,
                    type: 'POST',
                    url: '/patient-guides/delete_patient_guide_document',
                    cache: false,
                    success: function (response) {
                        row.parentNode.removeChild(row);
                        Swal.fire(
                            'Operação realizada com sucesso!',
                            'Documento excluído com sucesso.',
                            'success'
                        );
                        toastr.success(
                            'Documento excluídd com sucesso!',
                            'Operação realizada com Sucesso'
                        );
                    },
                    error: function (ajaxContext) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Não foi possivel realizar a operação',
                        });
                    },
                });
            }
        });
    });


    $(document).on('click', '.remove_document_patient_guide', function (event) {
        event.preventDefault();
        var row = this.parentNode.parentNode;
        Swal.fire({
            title: 'Deseja remover esse documento na guia do paciente?',
            text: 'Será excluído o documento permanentemente na guia do paciente !',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, desejo excluir!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                let dados = {
                    document_id: this.firstElementChild.value,
                };
                $.ajax({
                    data: dados,
                    type: 'POST',
                    url: '/patient-guides/delete_patient_guide_document_item',
                    cache: false,
                    success: function (response) {
                        row.parentNode.removeChild(row);
                        Swal.fire(
                            'Operação realizada com sucesso!',
                            'Documento excluído com sucesso.',
                            'success'
                        );
                        toastr.success(
                            'Documento excluídd com sucesso!',
                            'Operação realizada com Sucesso'
                        );
                    },
                    error: function (ajaxContext) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Não foi possivel realizar a operação',
                        });
                    },
                });
            }
        });
    });


    $('#tab_procediment_guides a').on('click', function (e) {
        e.stopImmediatePropagation();
        var patient_guide_id = document.getElementById('patient_guide_id').value;
        if (patient_guide_id != "") {
            $(this).tab('show');
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Não foi possivel realizar a operação, salve a guia primeiro.',
            });
        }
    });


    // salvar documentos guia do paciente
    $('#save_document_patient_guide').click(function (event) {
        event.preventDefault();

        Swal.fire({
            title: 'Deseja salvar o documento na guia do paciente?',
            text: 'Salvando o documento poderá ser vizualizado/baixado na guia do paciente!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, desejo salvar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                let dados = {
                    patient_guide_id: $('#patient_guide_id').val(),
                    name_document: $('#name_document').val(),
                    body_document: CKEDITOR.instances.body_document.getData()
                };
                $.ajax({
                    data: dados,
                    type: 'POST',
                    url: '/patient-guides/save_document_patient_guide',
                    cache: false,
                    success: function (response) {
                        $('#table_patient_guide_document').append(`<tr>
                            <td>${response['name_document']}</td>
                            <td>${response['created_at']
                            .replace(/T/, ' ')
                            .replace(/\..+/, '')}</td>
                                <td>${response['name_document']}</td>
                                <td></td>
                                <td class="text-center">
                                <a href="javascript:" class="btn btn-link text-danger p-0 remove_document_patient_guide">
                                <input type="hidden" name="document_id" id="document_id" value="${response['id']}" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x icon wh-15"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                </a>
                                <a href="/patient-guides/download_document.pdf?id=${response['id']}" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download icon"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                                </a>
                                </td>
                                </tr>
                                </tr>`);
                        $('.ExtraLargeModal').modal('hide');
                        Swal.fire(
                            'Operação realizada com sucesso!',
                            'Documento salvo com sucesso.',
                            'success'
                        );
                        toastr.success(
                            'Documento salvo com sucesso!',
                            'Operação realizada com Sucesso'
                        );
                    },
                    error: function (ajaxContext) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Não foi possivel realizar a operação',
                        });
                    },
                });

            }
            ;
        });

    });


    $('#botao-criar-documento').click(function (event) {
        event.preventDefault();

        let dados = {
            document_type_id: $('#document_type_id').val(),
        };

        $.ajax({
            data: dados,
            type: 'GET',
            url: '/patient-guides/get_document_patient_guide',
            cache: false,
            success: function (response) {
                $('#name_document').val(response['name']);
                CKEDITOR.instances.body_document.setData(response['body_document_type']);
            },
        });
    });

    // marca cidade selecionada
    $('#document_type_id').on('change', function () {
        //console.log('teste');
        var selected = $('#document_type_id option:selected').val();
        //console.log(selected);
        if (selected > 0) {
            $('#botao-criar-documento').prop('disabled', false);
        } else {
            $('#botao-criar-documento').prop('disabled', true);
        }

    });

    // remove documentos guia do paciente
    $(document).on('click', '.salve_permission', function (event) {
        // event.preventDefault();
        var jsonString = {}
        var array_permission = []
        $('.json_permission').each(function () {
            var obj = new Object();
            obj.permission_name = $(this).attr('id')
            obj.value = $(this).prop('checked');
            array_permission = array_permission.concat(obj)
        });
        jsonString = JSON.stringify(array_permission);
        $("#person_permissions_json").val(jsonString);
        var array_permission = []
        var array_permission2 = []
        $('.json_licensends').each(function () {
            array_permission.push($(this).attr('id'));
            array_permission2.push(true);
        });
        //jsonString = JSON.stringify(array_permission);
        $("#person_licensend_ids").val(array_permission);
        $("#person_licensend_ativos").val(array_permission2);
        true;
    });


    // seleciona todos os licensiados 
    $('#select_all_licenseds').on('change', function () {
        var checked = document.getElementById("select_all_licenseds").checked;
        if (checked){
            if(window.location.href.indexOf("new") != -1){
                $("#liceciados_item > option").each(function() {
                   if(this.value != ""){
                        $('#table_licenciados').append(`<tr>
                            <td>${this.value}</td>
                            <td>${this.text }</td>
                            <td><a href="javascript:" class="btn btn-link text-danger p-0 remove_licenciado_item_new">
                            <input type="hidden" name="licenced_id_${this.value}" id="${this.value}" class="json_licensends" value="${this.value}" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x icon wh-15"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                            </a></td>
                            </tr>`);
                   }
                });
    
               }else{
                $("#table_licenciados tr").remove();
                $("#liceciados_item > option").each(function() {
                    
                    if(this.value != ""){
                        $('#table_licenciados').append(`<tr>
                        <td>${this.value}</td>
                        <td>${this.text }</td>
                        <td><a href="javascript:" class="btn btn-link text-danger p-0 remove_licenciado_item">
                        <input type="hidden" name="licenced_id_${this.value}" id="${this.value}" class="json_licensends" value="${this.value}" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x icon wh-15"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                        </a></td>
                        </tr>`);
                    }
                });
               }
        }
    });

});
;